import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reace',
  templateUrl: './reace.component.html',
  styleUrls: ['./reace.component.scss']
})
export class ReaceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
