import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customized-software-app-dev',
  templateUrl: './customized-software-app-dev.component.html',
  styleUrls: ['./customized-software-app-dev.component.scss']
})
export class CustomizedSoftwareAppDevComponent implements OnInit {
   readMoreText: string="Read More";
   readMoreTextTwo: string="Read More";

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  clickEvent(){
  	if(this.readMoreText=="Read More"){
  		this.readMoreText = "Read Less";     
  	}
  	else
  	{
  		this.readMoreText = "Read More"; 
      
  	}
  	}

}
