import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NavComponent } from './nav/nav.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorComponent } from './error/error.component';
import { ApplicationMaintenanceAndSupportsComponent } from './application-maintenance-and-supports/application-maintenance-and-supports.component';
import { CareerComponent } from './career/career.component';
import { CloudComputingComponent } from './cloud-computing/cloud-computing.component';
import { CloudServicesComponent } from './cloud-services/cloud-services.component';
import { CmartComponent } from './cmart/cmart.component';
import { CustomizedSoftwareAppDevComponent } from './customized-software-app-dev/customized-software-app-dev.component';
import { DigitalClassComponent } from './digital-class/digital-class.component';
import { DigitalMarketingServicesComponent } from './digital-marketing-services/digital-marketing-services.component';
import { FieldBuzComponent } from './field-buz/field-buz.component';
import { FilmOnTopComponent } from './film-on-top/film-on-top.component';
import { IplSportsComponent } from './ipl-sports/ipl-sports.component';
import { ISCCIComponent } from './iscci/iscci.component';
import { LiteracyIndiaComponent } from './literacy-india/literacy-india.component';
import { MobileAppDevelopementComponent } from './mobile-app-developement/mobile-app-developement.component';
import { PashuchikistaComponent } from './pashuchikista/pashuchikista.component';
import { PashuchikistaMobileComponent } from './pashuchikista-mobile/pashuchikista-mobile.component';

import { ResponsiveWebsitesDevelopmentComponent } from './responsive-websites-development/responsive-websites-development.component';
import { SaasbasedApplicationDevelopmentComponent } from './saasbased-application-development/saasbased-application-development.component';
import { ServicesComponent} from './services/services.component';
import { SoftwareProductDevelopmentComponent } from './software-product-development/software-product-development.component';
import { VeertravellinksComponent } from './veertravellinks/veertravellinks.component';
import { WhyTechelitaComponent } from './why-techelita/why-techelita.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { APPolingComponent } from './appoling/appoling.component';
import { TermsOfuseComponent } from './terms-ofuse/terms-ofuse.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AgmCoreModule } from '@agm/core';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyCultureComponent } from './company-culture/company-culture.component';
import { TrailbuzProbeComponent } from './trailbuz-probe/trailbuz-probe.component';
import { ReaceComponent } from './reace/reace.component';
import { AgrisellComponent } from './agrisell/agrisell.component';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material';
import { AlphabetDirective } from '../app/utils/directives/AlphabetsOnly';
import { NumberDirective } from '../app/utils/directives/NumberOnly';
import {ToastModule} from 'primeng/toast';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NativeAndHybridMobileAppsComponent } from './native-and-hybrid-mobile-apps/native-and-hybrid-mobile-apps.component';
import { ErpCrmComponent } from './erp-crm/erp-crm.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { RoboticProcessAutomationComponent } from './robotic-process-automation/robotic-process-automation.component';
import { ItConsultingComponent } from './it-consulting/it-consulting.component';
import { DataAnalyticsAndAiComponent } from './data-analytics-and-ai/data-analytics-and-ai.component';
import { EGovernanceComponent } from './e-governance/e-governance.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { WebPortalDevelopmentComponent } from './web-portal-development/web-portal-development.component';
import { ClientsProjectComponent } from './clients-project/clients-project.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    NavComponent,
    ContactComponent,
    FooterComponent,
    ErrorComponent,
    ApplicationMaintenanceAndSupportsComponent,
    CareerComponent,
    CloudComputingComponent,
    CloudServicesComponent,
    CmartComponent,
    CustomizedSoftwareAppDevComponent,
    DigitalClassComponent,
    DigitalMarketingServicesComponent,
    FieldBuzComponent,
    FilmOnTopComponent,
    IplSportsComponent,
    ISCCIComponent,
    LiteracyIndiaComponent,
    MobileAppDevelopementComponent,
    PashuchikistaComponent,
    PashuchikistaMobileComponent,

    ResponsiveWebsitesDevelopmentComponent,
    SaasbasedApplicationDevelopmentComponent,
    ServicesComponent,
    SoftwareProductDevelopmentComponent,
    VeertravellinksComponent,
    WhyTechelitaComponent,
    PortfolioComponent,
    APPolingComponent,
    TermsOfuseComponent,
    PrivacyPolicyComponent,
    CompanyProfileComponent,
    CompanyCultureComponent,
    TrailbuzProbeComponent,
    ReaceComponent,
    AgrisellComponent,
    NumberDirective,
    AlphabetDirective,
    NativeAndHybridMobileAppsComponent,
    ErpCrmComponent,
    InternetOfThingsComponent,
    RoboticProcessAutomationComponent,
    ItConsultingComponent,
    DataAnalyticsAndAiComponent,
    EGovernanceComponent,
    HelpdeskComponent,
    WebPortalDevelopmentComponent,
    ClientsProjectComponent
   
  ],
  imports: [
    BrowserModule,
    CommonModule,
    // ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ToastModule,
    RecaptchaModule.forRoot(),
    

    AgmCoreModule.forRoot({
      apiKey:'AIzaSyAr4wm9NB9K5S4ANY6CNDOKbtqw5wgXdUE',
      libraries: ["places"]
      //This is a GoogleAPIs Key. I already created using Google developer account.
    })
  ],
  providers: [
  // providers: [
    {provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
