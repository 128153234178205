import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FormsUtils } from '@app/utils/reactive-forms.utils';
import { LookUpService } from '@app/service/lookup.service';
import { ToastService } from '@app/service/toast.service';
import { FileValidationUtils } from "@app/utils/file-validation.utils";
declare var  $;
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  emailPattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  careerCtrl = {
    name: [null, [Validators.required]],
    mob_phone: [null, [Validators.required,Validators.minLength(10)]],
    email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
    query: [null,[Validators.required]],
    destination:[null,[Validators.required]],
    file:[null,[Validators.required]]
  };
  creerForm: FormGroup = this.fb.group(this.careerCtrl);
  errorMsg: any;
  base64textString;
  fileName;
  imgError: string;
  fileForUpload: any;
  constructor(
    private fb: FormBuilder,
    private lookUpService:LookUpService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    $('#chooseFile').bind('change', function () {
     
  var filename = $("#chooseFile").val();
  if (/^\s*$/.test(filename)) {
    $(".file-upload").removeClass('active');
    $("#noFile").text("No file chosen..."); 
  }
  else {
    $(".file-upload").addClass('active');
    $("#noFile").text(filename.replace("C:\\fakepath\\", "")); 
  }
});



$(document).ready(function(){

//$('.single-item').slick();

$('.single-item').slick({
  dots: false,
  infinite: true,
  speed: 500,
  fade: true
  
});
  
});


  }
  onFileUpload(evt) {
    var files = evt.target.files;
    this.fileForUpload = evt.target.files[0];
    var file = files[0];
    this.imgError = FileValidationUtils.validateFile(this.fileForUpload);
    if (this.imgError) {
      this.toastService.error(this.imgError);
      return;
    }
    this.fileName = evt.target.files[0].name;
  if (files && file) {
      var reader = new FileReader();

      reader.onload =this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
  }
}



_handleReaderLoaded(readerEvt) {
  var binaryString = readerEvt.target.result;
         this.base64textString= btoa(binaryString);
        //  console.log(btoa(binaryString));
 }
  sendMail(){   
    if(this.creerForm.invalid || this.imgError){
      FormsUtils.markFormGroupTouched(this.creerForm);     
      if (this.base64textString == null || this.base64textString == undefined) {
        this.errorMsg = 'Upload your resume';      
        return;
      }
      else {
        this.errorMsg = '';
      }    
      return;
    }

   let carrerFormData={...this.creerForm.value,file : this.base64textString ,fileName : this.fileName}
    this.lookUpService.careerMailService(carrerFormData).subscribe(
      data => {
        this.toastService.success(data.message);
        this.creerForm.reset();       
      },
      err => {
        this.toastService.error('Request Failed');        
      }
    );  
  }
  isInvalid(control: string) {
    return (
      this.creerForm.controls[control].invalid &&
      this.creerForm.controls[control].touched
    );
  }

}
