import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-app-developement',
  templateUrl: './mobile-app-developement.component.html',
  styleUrls: ['./mobile-app-developement.component.scss']
})
export class MobileAppDevelopementComponent implements OnInit {
  
  readMoreText: string="Read More";
  readMoreTextTwo: string="Read More";

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  clickEvent(){
  	if(this.readMoreText=="Read More"){
  		this.readMoreText = "Read Less";     
  	}
  	else
  	{
  		this.readMoreText = "Read More"; 
      
  	}
  	}

}
