import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-buz',
  templateUrl: './field-buz.component.html',
  styleUrls: ['./field-buz.component.scss']
})
export class FieldBuzComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
