import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-computing',
  templateUrl: './cloud-computing.component.html',
  styleUrls: ['./cloud-computing.component.scss']
})
export class CloudComputingComponent implements OnInit {
  readMoreText: string="Read More";
  readMoreTextTwo: string="Read More";
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  clickEvent(){
  	if(this.readMoreText=="Read More"){
  		this.readMoreText = "Read Less";     
  	}
  	else
  	{
  		this.readMoreText = "Read More"; 
      
  	}
  	}


}
