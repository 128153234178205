import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-consulting',
  templateUrl: './it-consulting.component.html',
  styleUrls: ['./it-consulting.component.scss']
})
export class ItConsultingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  window.scrollTo(0, 0);
  }

}
