import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-analytics-and-ai',
  templateUrl: './data-analytics-and-ai.component.html',
  styleUrls: ['./data-analytics-and-ai.component.scss']
})
export class DataAnalyticsAndAiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  window.scrollTo(0, 0);
  }

}
