import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iscci',
  templateUrl: './iscci.component.html',
  styleUrls: ['./iscci.component.scss']
})
export class ISCCIComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }


}
