import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-techelita',
  templateUrl: './why-techelita.component.html',
  styleUrls: ['./why-techelita.component.scss']
})
export class WhyTechelitaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
