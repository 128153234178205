import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robotic-process-automation',
  templateUrl: './robotic-process-automation.component.html',
  styleUrls: ['./robotic-process-automation.component.scss']
})
export class RoboticProcessAutomationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  window.scrollTo(0, 0);
  }

}
