import { Component, OnInit } from '@angular/core';
declare var  $;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    $('#lightbox a').nivoLightbox({
      effect: 'fall',                             
  });
  }

}
