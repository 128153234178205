import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-native-and-hybrid-mobile-apps',
  templateUrl: './native-and-hybrid-mobile-apps.component.html',
  styleUrls: ['./native-and-hybrid-mobile-apps.component.scss']
})
export class NativeAndHybridMobileAppsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	window.scrollTo(0, 0);
  	
  }

}
