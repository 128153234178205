import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erp-crm',
  templateUrl: './erp-crm.component.html',
  styleUrls: ['./erp-crm.component.scss']
})
export class ErpCrmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	window.scrollTo(0, 0);
  }

}
