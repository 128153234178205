import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare var  $;
@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  navigationSubscription;
  constructor(private router: Router) {
    this.navigationSubscription = router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
            this.initialiseInvites();
        }
      });
  }

  initialiseInvites() {
    window.scrollTo(0, 0);
    $('.timeline').timeline({
        mode: 'horizontal',
        forceVerticalMode: 600,
        verticalStartPosition:'left',
        visibleItems: 4,
        horizontalStartPosition:'top',
        verticalTrigger:'15%',
        moveItems: 1,
      });
  }

  ngOnInit() {
  

  }

}
