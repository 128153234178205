import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FormsUtils } from '@app/utils/reactive-forms.utils';
import { LookUpService } from '@app/service/lookup.service';
import { ToastService } from '@app/service/toast.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  lat: number = 13.003980799999999;
  lng: number = 77.66507519999999;

  lat2: number = 28.471874;
  lng2: number = 77.101647;
  emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  contactCtrl = {
    name: ['', [Validators.required]],
    email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
    mob_phone: [null, [Validators.required, Validators.minLength(10)]],
    query: [null, [Validators.required]],
    subject: [null, [Validators.required]]
    // captcha: ['', [Validators.required]]
  };
  contactForm: FormGroup = this.fb.group(this.contactCtrl);
  errorMsg: any;
  captchaResponse;
  constructor(
    private fb: FormBuilder,
    private lookUpService: LookUpService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse.length > 0) {
      //reCaptcha not verified
      this.errorMsg = '';

      return false;
    }
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  sendMail() {
    if (this.contactForm.invalid) {
      FormsUtils.markFormGroupTouched(this.contactForm);   
      if (this.captchaResponse == null || this.captchaResponse == undefined) {     
        this.errorMsg = 'Please verify you are human';
        return;
      } else {
        this.errorMsg = '';
      }   
      return;
      
    }
    if (this.captchaResponse == null || this.captchaResponse == undefined) {     
      this.errorMsg = 'Please verify you are human';
      return;
    } else {
      this.errorMsg = '';
    }

    this.lookUpService.contactUsMailService(this.contactForm.value).subscribe(
      data => {
        console.log(data)
        this.toastService.success('We received your request.we will get back to you soon');
        this.contactForm.reset();
      },
      err => {
        this.toastService.error('Contact us  Failed');
      }
    );

  }
  isInvalid(control: string) {
    return (
      this.contactForm.controls[control].invalid &&
      this.contactForm.controls[control].touched
    );
  }
  onMouseOut(infoWindow, gm) {
    gm.lastOpen.close();
  }
  onMouseOver(infoWindows, gm) {

    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindows;

    infoWindows.open();
  }
  onMouseOverB(infoWindows, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindows;
    infoWindows.open();
  }

  clickedMarker(latitude, longitude) {
    window.open("https://maps.google.com/?q=" + latitude + "," + longitude + "", "_blank");
  }
}
