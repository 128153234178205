import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { WhyTechelitaComponent } from './why-techelita/why-techelita.component';
import { CompanyCultureComponent } from './company-culture/company-culture.component';
import { ServicesComponent } from './services/services.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ClientsProjectComponent } from './clients-project/clients-project.component';
import { CareerComponent } from './career/career.component';
import { NativeAndHybridMobileAppsComponent } from './native-and-hybrid-mobile-apps/native-and-hybrid-mobile-apps.component';
import { ErpCrmComponent } from './erp-crm/erp-crm.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { RoboticProcessAutomationComponent } from './robotic-process-automation/robotic-process-automation.component';
import { ItConsultingComponent } from './it-consulting/it-consulting.component';
import { EGovernanceComponent } from './e-governance/e-governance.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { WebPortalDevelopmentComponent } from './web-portal-development/web-portal-development.component';
import { DataAnalyticsAndAiComponent } from './data-analytics-and-ai/data-analytics-and-ai.component';
import { CustomizedSoftwareAppDevComponent } from './customized-software-app-dev/customized-software-app-dev.component';
import { SoftwareProductDevelopmentComponent } from './software-product-development/software-product-development.component';
import { ApplicationMaintenanceAndSupportsComponent } from './application-maintenance-and-supports/application-maintenance-and-supports.component';
import { ResponsiveWebsitesDevelopmentComponent } from './responsive-websites-development/responsive-websites-development.component';
import { DigitalMarketingServicesComponent } from './digital-marketing-services/digital-marketing-services.component';
import { MobileAppDevelopementComponent } from './mobile-app-developement/mobile-app-developement.component';
import { CloudComputingComponent } from './cloud-computing/cloud-computing.component';
import { SaasbasedApplicationDevelopmentComponent } from './saasbased-application-development/saasbased-application-development.component';
import { FieldBuzComponent } from './field-buz/field-buz.component';
import { TrailbuzProbeComponent } from './trailbuz-probe/trailbuz-probe.component';
import { ReaceComponent } from './reace/reace.component';
import { AgrisellComponent } from './agrisell/agrisell.component';
import { FilmOnTopComponent } from './film-on-top/film-on-top.component';
import { PashuchikistaComponent } from './pashuchikista/pashuchikista.component';
import { VeertravellinksComponent } from './veertravellinks/veertravellinks.component';
import { LiteracyIndiaComponent } from './literacy-india/literacy-india.component';
import { ISCCIComponent } from './iscci/iscci.component';
import { PashuchikistaMobileComponent } from './pashuchikista-mobile/pashuchikista-mobile.component';
import { CloudServicesComponent } from './cloud-services/cloud-services.component';
import { DigitalClassComponent } from './digital-class/digital-class.component';
import { IplSportsComponent } from './ipl-sports/ipl-sports.component';
import { CmartComponent } from './cmart/cmart.component';
import { APPolingComponent } from './appoling/appoling.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfuseComponent } from './terms-ofuse/terms-ofuse.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { from } from 'rxjs';
const routes: Routes = [
  { path:'', component: HomeComponent },
  { path:'home', component: HomeComponent },
  { path:'about', component: AboutComponent },
  { path:'contact', component: ContactComponent },
  { path:'why-us', component: WhyTechelitaComponent },
  { path:'company-culture', component: CompanyCultureComponent },
  { path:'services', component: ServicesComponent},
  { path:'projects', component: PortfolioComponent },
  { path:'products', component: ClientsProjectComponent },
  { path:'career', component: CareerComponent },
  { path:'native-and-hybrid-mobile-apps', component: NativeAndHybridMobileAppsComponent },
  { path:'erp-crm', component: ErpCrmComponent },
  { path:'internet-of-things', component: InternetOfThingsComponent},
  { path:'robotic-process-automation', component: RoboticProcessAutomationComponent},
  { path:'it-consulting', component: ItConsultingComponent},
  { path:'data-analytics-and-ai', component: DataAnalyticsAndAiComponent},
  { path:'e-governance', component: EGovernanceComponent},
  { path:'colud-services', component: CloudServicesComponent },
  { path:'helpdesk', component: HelpdeskComponent},
  { path:'web-portal-development', component: WebPortalDevelopmentComponent},
  { path:'customized-software-app-development', component: CustomizedSoftwareAppDevComponent },
  { path:'software-product-app-development', component: SoftwareProductDevelopmentComponent },
  { path:'application-maitanance-and-supports', component: ApplicationMaintenanceAndSupportsComponent },
  { path:'responsiveWebsiteDevelopment', component: ResponsiveWebsitesDevelopmentComponent},
  { path:'digitalMarketingServices', component: DigitalMarketingServicesComponent },
  { path:'mobileAppDevelopment', component: MobileAppDevelopementComponent },
  { path:'cloudComputing', component: CloudComputingComponent },
  { path:'saasbasedAppDevelopment', component: SaasbasedApplicationDevelopmentComponent },
  { path:'tralibuz-force', component: FieldBuzComponent },
  { path:'tralibuz-probe', component: TrailbuzProbeComponent },
  { path:'agrisell', component: AgrisellComponent },
  { path:'reace', component: ReaceComponent },
  { path:'Filmontop', component: FilmOnTopComponent },
  { path:'Pashucchikista', component: PashuchikistaComponent },
  { path:'Veertravellink', component: VeertravellinksComponent },
  { path:'LiteracyIndia', component: LiteracyIndiaComponent },
  { path:'ISCCI', component: ISCCIComponent },
  { path:'PashukchikistaMob', component: PashuchikistaMobileComponent },
  { path:'DigitalClass', component: DigitalClassComponent },
  { path:'IplSports', component: IplSportsComponent },
  { path:'Cmart', component: CmartComponent },
  { path:'Appoling', component: APPolingComponent },
  { path:'PrivacyPolicy', component: PrivacyPolicyComponent },
  { path:'TermsOfuse', component: TermsOfuseComponent },
  { path:'company-profile', component: CompanyProfileComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
