import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-product-development',
  templateUrl: './software-product-development.component.html',
  styleUrls: ['./software-product-development.component.scss']
})
export class SoftwareProductDevelopmentComponent implements OnInit {
  readMoreText: string="Read More";
  readMoreTextTwo: string="Read More";
  
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  clickEvent(){
  	if(this.readMoreText=="Read More"){
  		this.readMoreText = "Read Less";     
  	}
  	else
  	{
  		this.readMoreText = "Read More"; 
      
  	}
  	}

}
