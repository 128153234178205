import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsive-websites-development',
  templateUrl: './responsive-websites-development.component.html',
  styleUrls: ['./responsive-websites-development.component.scss']
})
export class ResponsiveWebsitesDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
