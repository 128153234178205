import { Component, OnInit } from '@angular/core';
declare var  $;
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    $('#lightbox a').nivoLightbox({
      effect: 'fall',                             
  });
  }
  

}
