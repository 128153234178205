import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agrisell',
  templateUrl: './agrisell.component.html',
  styleUrls: ['./agrisell.component.scss']
})
export class AgrisellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
