import { Component, OnInit } from '@angular/core';
declare var  $;
@Component({
  selector: 'app-clients-project',
  templateUrl: './clients-project.component.html',
  styleUrls: ['./clients-project.component.scss']
})
export class ClientsProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  window.scrollTo(0, 0);
  $('#lightbox a').nivoLightbox({
      effect: 'fall',                             
  });
  }

}
