import { Component, OnInit } from '@angular/core';
declare var  $;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    $('#lightbox a').nivoLightbox({
      effect: 'fall',                             
  });
  }

}
