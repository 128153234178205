import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-maintenance-and-supports',
  templateUrl: './application-maintenance-and-supports.component.html',
  styleUrls: ['./application-maintenance-and-supports.component.scss']
})
export class ApplicationMaintenanceAndSupportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
