import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import{ environment } from '../../environments/environment';
// import { of, zip, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LookUpService {
  constructor(private http: HttpClient) {}

  // lookupCdCache = {};
  // lookupCache = {};

  // getLookUpCache() {
  //   return this.lookupCache;
  // }

  // getAllCountries() {
  //   return this.http.get<any>('countries');
  // }

  // getStatesByCountry(countryId) {
  //   return this.http.get<any>(`states/${countryId}`);
  // }

  // getCitiesByState(stateId) {
  //   return this.http.get<any>(`cities/${stateId}`);
  // }

  // getLookUpData(lookUpCd) {
  //   if (this.lookupCdCache[lookUpCd]) {
  //     return of(this.lookupCdCache[lookUpCd]);
  //   }
  //   return this.http.get<any>(`lkup/${lookUpCd}`).pipe(
  //     tap(data => {
  //       this.lookupCdCache[lookUpCd] = data;
  //       data.forEach(d => {
  //         this.lookupCache[d.lkup_value] = d.lkup_value_desc;
  //       });
  //     })
  //   );
  // }

  // populateCacheMapForlkupCds(lkupCds: string[]) {
  //   const lkupCds$ = lkupCds.map(cd => this.getLookUpData(cd));
  //   return forkJoin(lkupCds$);
  // }
  // getPropertyType() {
  //   return this.getLookUpData('PROPTYPE');
  // }
  // getSubCategory(lkup_value) {
  //   return this.getLookUpData(lkup_value);
  // }
  // getUnits() {
  //   return this.getLookUpData('NUMDD');
  // }
  // getFurStatus() {
  //   return this.getLookUpData('RESSTS');
  // }
  // getCurrentstatus() {
  //   return this.getLookUpData('CSTUS');
  // }
  // getAreaUnit() {
  //   return this.getLookUpData('AUNIT');
  // }
  // getAreaUnitMF() {
  //   return this.getLookUpData('AMUNIT');
  // }
  // getSpecialServicesType() {
  //   return this.getLookUpData('SPELSERVTYPE');
  // }
  // getTransactionType() {
  //   return this.getLookUpData('TRANTYPE');
  // }
  // getLandZone(){
  //   return this.getLookUpData('LANDZONE');
  // }
  // getPropRegistrationType() {
  //   return this.getLookUpData('PROPREGTYPE');
  // }
  // getFacingType(){
  //   return this.getLookUpData('FACING')
  // }
  careerMailService(careerdetails){
    return this.http.post<any>('https://node.techaffinityconsulting.com/Career', careerdetails);
  }
  contactUsMailService(contactdetails){
    console.log("hi",contactdetails);
    return this.http.post<any>('https://node.techaffinityconsulting.com/Contact',contactdetails);
  }
  // getLandArea(){
  //   return this.getLookUpData('LANDAREA');
  // }
}
