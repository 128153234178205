

export class FileValidationUtils {




  public static validate(fileForUpload): string {
    const validExts = ['image/jpeg', 'image/png', 'image/jpg'];
    if (fileForUpload) {
      if (fileForUpload.size > 5242880) {
       return  'Maximum file size should be 5MB';
      }
      if (!validExts.includes(fileForUpload.type)) {
       return 'Allowed formats are jpeg, png and jpg';
      }
    }
  }

  public static validateFile(fileForUpload): string {
    console.log("inside validate file")
    const validExts = ['application/pdf','application/msword'];
    if (fileForUpload) {
     
      if (fileForUpload.size > 5242880) {
       return  'Maximum file size should be 5MB';
      }
      if (!validExts.includes(fileForUpload.type)) {
       return 'Allowed formats are  pdf, msword';
      }
    }
  }
}
