import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare var  $;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	navigationSubscription;
	constructor(private router: Router) {
		this.navigationSubscription = router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	initialiseInvites() {
		if ($('.banner-carousel').length) {
			$('.banner-carousel').owlCarousel({
				animateOut: 'fadeOut',
				animateIn: 'fadeIn',
				loop:true,
				margin:0,
				nav:true,
				smartSpeed: 700,
				autoHeight: true,
				autoplay: true,
				autoplayTimeout:10000,
				navText: [ '<span class="fa fa-long-arrow-left"></span> prev', 'next<span class="fa fa-long-arrow-right"></span>' ],
				responsive:{
					0:{
						items:1
					},
					600:{
						items:1
					},
					1024:{
						items:1
					},
				}
			});       
		}

		$('.customer-logos').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1500,
			arrows: false,
			dots: false,
			pauseOnHover: false,
			responsive: [{
				breakpoint: 768,
				settings: {
					slidesToShow: 4
				}
			}, {
				breakpoint: 520,
				settings: {
					slidesToShow: 3
				}
			}]
		});


		$('.client-logos').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1500,
			arrows: false,
			dots: false,
			pauseOnHover: false,
			responsive: [{
				breakpoint: 768,
				settings: {
					slidesToShow: 4
				}
			}, {
				breakpoint: 520,
				settings: {
					slidesToShow: 3
				}
			}]
		});

		$(function() {
			$('#dg-container').gallery();
		});

		$('.center').slick({
			centerMode: true,
			centerPadding: '60px',
			slidesToShow: 2,
			autoplay: true,
			arrows: false,
			responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
			]
		});
	}

	ngOnInit() {
		window.scrollTo(0, 0);
		//     $.backstretch([
		//       "assets/img/bg/ag1.jpg", 
		//       "assets/img/bg/ag2.jpg",
		//       "assets/img/bg/ag3.jpg"
		//   ], {duration: 3000, fade: 750});

		//   $('#lightbox a').nivoLightbox({
		//     effect: 'fall',                             
		// });

		$(document).ready(function(){
			
		});


		$(document).ready(function(){
			
		});

		$(document).ready(function(){

			        
		});
	}

}
