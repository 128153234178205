import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})

export class ToastService {
  constructor( private snackBar: MatSnackBar){}

  private toast(message: string, action = 'OK', panelClass) {
    this.snackBar.open(message, action, {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 2000
    });
  }

  success(message: string, action?: string){
    this.toast(message, action, 'success-bar');
  }

  error(message: string, action?: string){
    this.toast(message, action, 'success-bar');
  }


}
